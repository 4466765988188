<template>
  <div>
    <driver-create
      ref="driverCreate"
      :show.sync="showModal"
      @created-driver="handleCreatedDriver"
    />
    <b-card>
      <div class="p-3">
        <b-row>
          <b-col
            class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center"
          >
            <span class="card-title mb-md-0">
              Mensajeros
            </span>
            <buttons-layout>
              <z-dropdown-cities
                v-model="selectedCity"
                class="order-3 order-md-0"
              />
              <z-button
                v-tooltip.bottom="'Primero selecciona el grupo de mensajeros'"
                class="order-0 order-md-3"
                @click="showModal = true"
              >
                Crear Mensajero
              </z-button>
              <z-button
                v-tooltip.bottom="
                  !enableSendMessage
                    ? 'Debes filtar antes de enivar un mensaje'
                    : ''
                "
                class="order-1 order-md-2"
                variant="secondary"
                :disabled="!enableSendMessage"
                @click="showMessageModal = true"
              >
                Enviar Mensaje
              </z-button>
              <drivers-report-download
                class="order-2 order-md-1"
                :loading.sync="loading"
                :date-filter="selectedDate"
                :date="date"
                :search="searchText"
                :status="selectedStatus"
                :city="selectedCity"
                @error="handleReportError"
              />
            </buttons-layout>
          </b-col>
        </b-row>
        <b-row class="mt-5 mt-md-3">
          <b-col sm="12" md="6" lg="4" xl="3">
            <div class="search-filter d-flex flex-row align-items-center pl-3">
              <font-awesome-icon class="icon" icon="search" />
              <b-form-input
                v-model="searchText"
                class="flex-fill"
                placeholder="Busca por ID, nombre o correo"
                size="sm"
                type="search"
              />
            </div>
          </b-col>
          <b-col sm="12" md="2" class="mt-3 mt-md-0">
            <z-dropdown
              v-model="selectedStatus"
              name="Estado"
              :options="statusOptions"
            />
          </b-col>
          <b-col sm="12" md="2" class="mt-3 mt-md-0">
            <z-dropdown
              id="dropdown-date"
              v-model="selectedDate"
              :options="datesOptions"
              name="Fecha"
            />
          </b-col>
          <b-col v-show="showDatePicker" sm="12" md="2" class="mt-3 mt-md-0">
            <z-date-picker
              v-model="date.from"
              calendar-class="date-picker"
              :disabled-dates="disableDatesFrom"
              input-class="form-control bg-white w-100"
              placeholder="Desde"
            />
          </b-col>
          <b-col v-show="showDatePicker" sm="12" md="2" class="mt-3 mt-md-0">
            <z-date-picker
              v-model="date.until"
              calendar-class="date-picker"
              :disabled-dates="disableDatesUntil"
              input-class="form-control bg-white w-100"
              placeholder="Hasta"
            />
          </b-col>
        </b-row>
        <quick-message
          class="mt-3"
          with-icon
          :show.sync="hasMessage"
          :message="message"
          :type="hasError ? 'error' : 'success'"
        />
        <drivers-message-modal
          :show.sync="showMessageModal"
        ></drivers-message-modal>
        <b-row class="mt-4">
          <b-col>
            <z-table
              responsive
              hover
              :loading="isLoading"
              :items="listing"
              :fields="fields"
              :sort-by="sortBy"
              :sort-compare="customSort"
              @row-clicked="handleRowClick"
            >
              <template #empty>
                <p class="text-center py-5 my-5 font-weight-semi-bold">
                  No hay mensajeros para mostrar
                </p>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <loading-spinner />
                </div>
              </template>
              <!-- Headers -->
              <template #head(checkbox)>
                <b-form-checkbox id="checkbox-header" v-model="selectAll">
                </b-form-checkbox>
              </template>
              <!-- Cells -->
              <template v-slot:cell(checkbox)="row">
                <template v-if="selectAll">
                  <div
                    v-if="selected.find(el => el === row.item.id)"
                    class="custom-control custom-checkbox"
                    @click="removeFromSelected(row.item.id)"
                  >
                    <input
                      :id="`checkbox-${row.item.id}`"
                      type="checkbox"
                      class="custom-control-input"
                    /><label
                      :for="`checkbox-${row.item.id}`"
                      class="custom-control-label"
                    ></label>
                  </div>
                  <b-form-checkbox
                    v-else
                    :id="`checkbox-${row.item.id}`"
                    v-model="row.item.id"
                    :value="row.item.id"
                    @click.native="addSelected(row.item.id)"
                  >
                  </b-form-checkbox>
                </template>
                <b-form-checkbox
                  v-else
                  :id="`checkbox-${row.item.id}`"
                  v-model="selected"
                  :value="row.item.id"
                >
                </b-form-checkbox>
              </template>
              <template v-slot:cell(status)="row">
                <status-driver :status="row.item.status" />
              </template>
            </z-table>
            <z-table-pagination
              :total-rows="listingTotal"
              :per-page.sync="perPage"
              :current-page.sync="currentPage"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import DateFilters from "@zubut/common/src/constants/filters/date";
import ZTable from "@zubut/common/src/components/ZTable";
import { dateRange, formatToISO } from "@zubut/common/src/utils/time";
import { parseQueryParam } from "@zubut/common/src/utils/strings";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZDatePicker from "@zubut/common/src/components/ZDatePicker";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import { initialize } from "@/utils/array";
import DriverAccess from "@/constants/access/drivers";
import ConstantsDriverStatus from "@/constants/drivers/status";
import StatusDriver from "@/app/components/StatusDriver";
import ZDropdownCities from "@/app/components/ZDropdownCities.vue";
import notifyMixin from "@/mixins/notify";
import filtering from "@/mixins/filtering";
import DriverCreate from "./DriverCreate";
import DriversMessageModal from "@/app/components/DriversMessageModal/DriversMessageModal";
import { endOfDay, isBefore, startOfDay, format, formatISO } from "date-fns";
import * as mutation from "@/store/modules/drivers/mutations-types";
import _debounce from "lodash/debounce";
import _isEmpty from "lodash/isEmpty";
import DriversReportDownload from "./DriversReportDownload";
import ButtonsLayout from "@/app/layouts/ButtonsLayout.vue";

const moduleName = "drivers";

export default {
  name: "Drivers",

  components: {
    DriverCreate,
    DriversMessageModal,
    DriversReportDownload,
    StatusDriver,
    ZDropdownCities,
    ZTablePagination,
    ZDropdown,
    ZTable,
    ZDatePicker,
    ButtonsLayout
  },

  mixins: [notifyMixin("notifications"), filtering("drivers")],

  data() {
    return {
      sortBy: "lastLogin",
      drivers: [],
      isLoading: false,
      placeholderItem: {
        name: "",
        email: "",
        phone: "",
        createdAt: null,
        status: 0
      },
      showModal: false,
      hasError: false,
      isSucces: false,
      message: "",
      date: { from: null, until: null },
      loading: false,
      showMessageModal: false
    };
  },

  computed: {
    fields() {
      const fields = [];
      if (this.hasFilters) {
        fields.push({
          key: "checkbox",
          label: "",
          thStyle: {
            width: "40px",
            textAlign: "center"
          },
          tdClass: "text-center"
        });
      }
      fields.push(
        { key: "name", label: "Nombre" },
        { key: "email", label: "Email" },
        { key: "phone", label: "Teléfono" },
        {
          key: "createdAt",
          label: "Fecha de registro",
          sortable: true,
          formatter: value => format(new Date(value), "d MMM u")
        },
        { key: "city", label: "Ciudad" },
        { key: "status", label: "Estado" }
      );
      return fields;
    },

    listing() {
      return this.$store.getters[`${moduleName}/getLisintg`];
    },

    listingTotal() {
      return this.$store.getters[`${moduleName}/getListingTotal`];
    },

    searchText: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterText`];
      },
      set(val) {
        this.setFilter("search", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_TEXT}`,
          val
        );
      }
    },

    selectedCity: {
      get() {
        return this.$store.getters[`cities/getCity`];
      },
      set(val) {
        this.setFilter("city", val);
      }
    },

    selectedStatus: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterStatus`];
      },
      set(val) {
        this.setFilter("status", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_STATUS}`,
          val
        );
      }
    },

    selectedDate: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterDate`];
      },
      set(val) {
        this.setFilter("date", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_DATE}`,
          val
        );
      }
    },

    dateFrom: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterDateFrom`];
      },
      set(val) {
        this.setFilter("from", val?.getTime());
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_DATE_FROM}`,
          val
        );
      }
    },

    dateUntil: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterDateUntil`];
      },
      set(val) {
        this.setFilter("until", val?.getTime());
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_DATE_UNTIL}`,
          val
        );
      }
    },

    selectAll: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterSelectAll`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_SELECT_ALL}`,
          val
        );
      }
    },

    selected: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterSelected`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_SELECTED}`,
          val
        );
      }
    },

    statusOptions() {
      return [
        { value: null, text: "Todos" },
        ...ConstantsDriverStatus.filterOptions
      ];
    },

    datesOptions() {
      return [{ value: null, text: "Todos" }, ...DateFilters.simplifiedOptions];
    },

    showDatePicker() {
      return this.selectedDate === DateFilters.NUM_CUSTOM;
    },

    disableDatesFrom() {
      let date;
      if (this.date.until != null) {
        date = new Date(this.date.until);
      } else {
        date = new Date();
      }
      return { from: date };
    },

    disableDatesUntil() {
      let date;
      if (this.date.from != null) {
        date = new Date(this.date.from);
      } else {
        date = new Date();
      }
      return { to: date };
    },

    hasMessage: {
      get() {
        return this.hasError || this.isSucces;
      },
      set(newVal) {
        this.hasError = newVal;
        this.isSucces = newVal;
      }
    },

    totalSelectedDrivers() {
      return this.$store.getters[`${moduleName}/getTotalSelectedDrivers`];
    },

    hasFilters() {
      return (
        this.selectedStatus != null || this.selectedDate || this.searchText
      );
    },

    enableSendMessage() {
      return this.hasFilters && this.totalSelectedDrivers > 0;
    }
  },

  watch: {
    date: {
      deep: true,
      handler() {
        if (this.selectedDate === DateFilters.NUM_CUSTOM) {
          this.getListing();
        }
      }
    },
    selectAll() {
      this.selected = [];
    },
    searchText() {
      this.search();
    }
  },

  created() {
    this.setRouteFilters();

    /* Using debounce avoids duplicate calls caused by watchers */
    this.defaultListener = _debounce(() => this.getListing(), 100);
    this.searchFilterListener = _debounce(() => this.getListing(), 600);

    this.search = _debounce(() => {
      this.getListing();
    }, 600);
  },

  methods: {
    setRouteFilters() {
      const query = { ...this.$route.query };

      query.search = parseQueryParam(query.search);
      query.page = parseQueryParam(query.page);
      query.show = parseQueryParam(query.show);
      query.date = parseQueryParam(query.date);
      query.from = parseQueryParam(query.from);
      query.until = parseQueryParam(query.until);
      query.status = parseQueryParam(query.status);

      this.searchText = query.search ?? null;
      this.selectedDate = query.date ?? null;
      this.dateFrom = query.from ? new Date(query.from) : null;
      this.dateUntil = query.until ? new Date(query.until) : null;
      this.selectedStatus = query.status ?? null;

      this.$router
        .replace({
          name: this.$route.name,
          query
        })
        .catch(() => {});
    },

    removeFromSelected(driverId) {
      this.selected = this.selected.filter(d => d !== driverId);
    },

    addSelected(driverId) {
      this.selected.push(driverId);
    },

    buildRequest() {
      const filter = {
        ...this.pagination,
        order: `${this.sortBy} DESC`
      };
      const where = { like: this.searchText };

      try {
        if (this.selectedStatus !== null) {
          where.status = this.selectedStatus;
        }

        if (this.selectedDate !== DateFilters.NUM_CUSTOM) {
          const newDate = dateRange(this.selectedDate);
          if (newDate.from != null && newDate.until != null) {
            newDate.from = formatToISO(newDate.from);
            newDate.until = formatToISO(newDate.until);
          }
          this.date = newDate;
        }

        if (this.date.from != null && this.date.until != null) {
          where.range = {
            from: formatISO(startOfDay(new Date(this.date.from))),
            until: formatISO(endOfDay(new Date(this.date.until)))
          };
        }

        if (this.selectedCity) {
          where.cityId = this.selectedCity;
        }
      } catch (err) {
        this.$captureError(err);
      }

      return { filter, where };
    },

    getListing() {
      this.isLoading = true;
      this.$store
        .dispatch("drivers/getListing", this.buildRequest())
        .then(({ data: drivers, meta }) => {
          if (meta.skip === 0) {
            this.totalRows = meta.count;
          }

          this.drivers = initialize({
            value: this.placeholderItem,
            size: this.totalRows
          });
          this.drivers.splice(meta.skip, drivers.length, ...drivers);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleRowClick(row) {
      if (this.$can(DriverAccess.READ_DRIVER_DETAIL, DriverAccess.moduleName)) {
        this.$router.push({ name: "driverDetail", params: { id: row.id } });
      }
    },

    customSort(a, b, key) {
      /* eslint no-nested-ternary: "off" */
      if (key === "createdAt") {
        if (isBefore(new Date(a.createdAt), new Date(b.createdAt))) {
          return 1;
        } else if (isBefore(new Date(b.createdAt), new Date(a.createdAt))) {
          return -1;
        }
        return 0;
      } else if (key === "name") {
        return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
      } else if (key === "status") {
        return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
      }
      return toString(a[key]).localeCompare(toString(b[key]), undefined, {
        numeric: true
      });
    },

    handleReportError(err) {
      if (err.statusCode === 404) {
        this.hasError = true;
        this.message = "Error al exportar datos, no hay datos disponibles.";
      } else {
        this.message = err.message;
        this.$captureError(err);
      }
    },

    handleCreatedDriver() {
      this.isSucces = true;
      this.message = "Mensajero creado exitosamente";
      this.getListing();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./drivers.scss";

.spinner {
  max-height: 30px;
  padding-top: 5px;
}
</style>
