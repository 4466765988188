<template>
  <status-badge :status-name="statusName" :type="colorVariant" />
</template>

<script>
import DriverStatus from "@/constants/drivers/status";
import StatusBadge from "@zubut/common/src/components/StatusBadge";
export default {
  name: "StatusDriver",
  components: { StatusBadge },
  props: {
    status: {
      type: Number,
      required: true
    }
  },
  computed: {
    statusName() {
      return DriverStatus.get[this.status];
    },
    colorVariant() {
      switch (this.status) {
        case DriverStatus.NUM_NEW:
          return "yellow";
        case DriverStatus.NUM_APPROVED:
        case DriverStatus.NUM_BILLING_REVISION:
        case DriverStatus.NUM_DOCS_REVISION:
        case DriverStatus.NUM_PENDING:
          return "blue";
        case DriverStatus.NUM_ACTIVE:
          return "green";
        case DriverStatus.NUM_BLOCKED:
        case DriverStatus.NUM_DENIED:
          return "red";
        default:
          return "red";
      }
    }
  }
};
</script>
