<template>
  <b-modal
    id="driver-modal"
    :visible="show"
    title="Crear nuevo mensajero"
    size="md"
    @hidden="cancel"
    @change="$emit('update:show', $event)"
  >
    <b-row>
      <b-col class="form-data" md="12" lg="12">
        <quick-message
          class="mb-2"
          :show="showError"
          type="error"
          dismissible
          :message="errorMessage"
        />
        <b-form @submit="createDriver">
          <b-form-group md="12">
            <label for="driver-name">
              Nombre *
            </label>
            <b-form-input
              id="driver-name"
              v-model="name"
              type="text"
              class="form-control"
              required
            />
            <label for="driver-mail">
              E-mail *
            </label>
            <b-form-input
              id="driver-mail"
              v-model="email"
              type="email"
              class="form-control"
              required
            />
            <label for="driver-phone">
              Teléfono *
            </label>
            <b-form-input
              id="driver-phone"
              v-model="phone"
              type="tel"
              class="form-control"
              required
            />
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:modal-footer class="w-100">
      <div>
        <z-button
          class="float-right"
          :disabled="!valid || requesting"
          :loading="requesting"
          @click="createDriver"
        >
          Aceptar
        </z-button>
        <z-button class="float-right mr-3" variant="secondary" @click="cancel">
          Cancelar
        </z-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import notifyMixin from "@/mixins/notify";
import { formatSendPhone } from "@zubut/common/src/utils/strings";

export default {
  mixins: [notifyMixin("notifications")],

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      name: "",
      email: "",
      phone: "",
      requesting: false,
      showError: false,
      errorMessage: ""
    };
  },

  computed: {
    valid() {
      return this.validMail && this.validPhone && this.validName;
    },

    validMail() {
      return this.email && this.email.length > 0;
    },

    validName() {
      return this.name && this.name.length > 0;
    },

    validPhone() {
      return this.phone && this.phone.length > 0;
    }
  },

  methods: {
    createDriver() {
      this.requesting = true;
      const { email, name, phone } = this;
      const data = { email, phone: formatSendPhone(phone), name };
      this.$store
        .dispatch("drivers/registerDriver", data)
        .then(() => {
          this.cancel();
          this.$emit("created-driver");
        })
        .catch(err => {
          this.showError = true;
          this.errorMessage = err.message;
          this.$captureError(err);
        })
        .finally(() => {
          this.requesting = false;
        });
    },

    cancel() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.showError = false;
      this.errorMessage = "";
      this.$emit("update:show", false);
    }
  }
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .modal-lg {
    width: 95% !important;
  }
}
</style>
