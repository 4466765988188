import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";
import FilterModalityOptions from "@/constants/transactions/filterModalityOptions";

modelObject.setModel("CSVContainers");

let CSVContainers = Object.assign(
  {},
  {
    generateDrivers(where) {
      return loopback.post("/CSVContainers/generateDrivers", where, {
        responseType: "blob"
      });
    },
    generateServices(where) {
      return loopback.post("/CSVContainers/generateServices", where, {
        responseType: "blob"
      });
    },
    generateUsers(where) {
      return loopback.post("/CSVContainers/generateUsers", where, {
        responseType: "blob"
      });
    },
    generateTransactions({ where = {}, addColumns = [], selectedFilter }) {
      const routeDirectory = {
        [FilterModalityOptions.DRIVERS_ACCOUNTING]:
          "/Csvcontainers/generateTransactions/drivers",
        [FilterModalityOptions.CUSTOMER_BALANCE]:
          "/Csvcontainers/generateTransactions/clients",
        [FilterModalityOptions.TRANSACTION_HISTORY]:
          "/CSVContainers/generateTransactions"
      };
      return loopback.post(
        routeDirectory[selectedFilter],
        { where, addColumns },
        {
          responseType: "blob"
        }
      );
    }
  },
  modelObject
);

export default CSVContainers;
